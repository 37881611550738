<template>      
  <div class="container">
    <div class="row">
      <div class="d-flex justify-content-between">
          <div>
              <h3>Hi, {{ curStaff?.firstName }}.</h3> 
          </div>                    
      </div>

      <hr class="divider-line0" />
      <div class="row">
        <h2>Your Ongoing Orders</h2>
      </div>
    </div>

    <div class="row">
      <ul v-infinite-scroll="load" :infinite-scroll-disabled="disabled" class="infinite-list" style="overflow: auto;"  >
        <li v-for="i in count" :key="i" class="infinite-list-item" >
          <div class="summary" v-if="orderData[i-1]">
            <div style="background-color: #F0F2F2;  padding-top: 6px; padding-bottom: 0px; padding-left: 16px; padding-right: 16px; font-size: 12px; color:#333;" class="d-flex justify-content-between">
              <div>
                {{ $t('orderList.topTitleOrderDate') }}<br>
                {{ MONTHS[new Date(orderData[i-1]?.createTime).getMonth()] }}. {{ new Date(orderData[i-1]?.createTime).getDate() }},
                {{ new Date(orderData[i-1]?.createTime).getFullYear() }}
              </div>
              <div style="color:firebrick">
                {{ $t('orderList.topTitleTotal') }}<br>
                {{ orderData[i-1]?.totalPoints}}
              </div>
              <div class="links" style="width:auto; padding-bottom: 4px;" :id="`#${orderData[i-1]?.orderNo}`">
                {{ $t('orderList.topTitleOrderNo') }} # {{ orderData[i-1]?.orderNo }}<br>
                USER # {{ orderData[i-1]?.userNo }}                               
              </div>
            </div>
            <div><hr class="divider-line1"/></div>
            <div style="padding: 6px; padding-left: 16px; padding-right: 16px; font-size: 16px;" class="d-flex justify-content-start">
              <div style="width:110px; padding: 10px; padding-left: 0px;">                
                <div :title="$t(getTxtCatalog(i-1))" style="padding-top: 0px; padding-bottom: 4px;"><img :src="getImagePath(orderData[i-1]?.serviceCatalog)" class="card-img-top img-fluid" style="width: 90px;" :alt="getTxtCatalog(i-1)" /></div>
                <div :style="{color: getStatusColor(orderData[i-1]?.status)}" style="text-align: center; font-weight: 600; font-size: 13px;">{{ $t(getTxtOrderStatus(i-1)) }}</div>
                <div style="padding-top: 15px;" class="d-flex justify-content-center">
                  <div class="d-flex justify-content-start" >
                    <div v-for="staff in orderData[i-1]?.staffs" :key="staff?.staffId">                                
                        <el-avatar :src="'/staffs/'+ staff?.staffNo + '.jpg'" fit="contain" class="avatarList"/>                                          
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style="color: chocolate; font-weight: 600; padding-bottom: 6px;">
                  {{ orderData[i-1]?.name }}  
                </div>
                <div style="font-size: 14px;">
                  {{ orderData[i-1]?.unit ? orderData[i-1]?.unit:'' }} {{ orderData[i-1]?.streetNo ? orderData[i-1]?.streetNo:'' }} 
                  {{ orderData[i-1]?.streetName }}<br>{{ orderData[i-1]?.city }}, {{ getTxtProvinceName(i-1) }} {{ orderData[i-1]?.postal }}
                </div>                       
                <div style="padding-top: 6px; width:100%;"><hr class="divider-line1"/></div>
                <div style="font-size: 14px; color: #0aa06e; font-weight: 600; padding-bottom: 6px;" >{{ $t(getDayOfWeekOnSiteTime(i-1)) }}, 
                  {{ MONTHS[new Date(orderData[i-1]?.strBookTime).getMonth()] }}. {{ new Date(orderData[i-1]?.strBookTime).getDate() }}, 
                  {{ new Date(orderData[i-1]?.strBookTime).getFullYear() }} <span v-if="orderData[i-1]?.serviceCatalog === 1" style="font-weight: 600; color:#333;">&nbsp;{{ proxy.$getTimeZone(new Date(orderData[i-1]?.strBookTime)) }}</span>
                  <span v-if="orderData[i-1]?.serviceCatalog === 1"><br>by {{ new Date(orderData[i-1]?.strBookTime).getHours() }}:{{ new Date(orderData[i-1]?.strBookTime).getMinutes() === 0 ? '00' : new Date(orderData[i-1]?.strBookTime).getMinutes() }} {{ getTxtSlot(new Date(orderData[i-1]?.strBookTime).getHours()) }}</span>
                  <span v-if="orderData[i-1]?.serviceCatalog != 1" style="font-weight: 600; color:#333;">&nbsp;{{ proxy.$getTimeZone(new Date(orderData[i-1]?.strBookTime)) }}</span>
                </div>                
                <div><hr class="divider-line1"/></div>
                <div >
                  <button :disabled="loading" class="submit-button" @click.prevent="handleServiceStartAndEnd(orderData[i-1]?.status, orderData[i-1]?.orderId)"><span v-if="!loading">{{ showBtnTextByOrderStatus(orderData[i-1]?.status) }}</span>
                    <span v-else class="spinner"></span></button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <p v-if="noMore" style="text-align: center; color: #333; font-size: 12px; padding-top: 8px;">{{ $t('orderList.reachEndTitle') }}</p>
    </div>
  </div>  
</template>
  

  <script setup>
  import { useRouter } from 'vue-router'; 
  import { useStore } from 'vuex';
  import { onMounted,ref,getCurrentInstance,computed } from 'vue';
  import { ElLoading } from 'element-plus'
  import { useToast } from 'vue-toastification';
  import apiClient from '@/apiClient';
  import { MONTHS,SERVICECATALOG,ORDERSTATUS,WEEKDAYS,PROVINCES} from '@/constants';

  const router = useRouter();
  const store = useStore();
  const count = ref(5);
  const orderData = ref([]);
  const toast = useToast();
  const { proxy } = getCurrentInstance();
  const errors = ref({}); 
  const slotAMStartHour = ref(9);
  const slotAMEndHour = ref(11);
  const slotPMStartHour = ref(12);
  const slotPMEndHour = ref(14);
  const loading = ref(false);

  const curStaff = store.getters.staff;

  const noMore = computed(()=> count.value >= orderData.value.length && orderData.value.length>0);
  const disabled = computed(() => noMore.value);

  const listCatalog = Number(router.currentRoute.value.params.catalog);


  function showBtnTextByOrderStatus(status) {
    if(status === 1) {
      return 'Start Service';
    } else if(status === 2) {
      return 'End Service';
    }
  }


  function getTxtSlot(hour) {
  if(hour >= slotAMStartHour.value && hour <= slotAMEndHour.value)
    return 'AM';
  else if(hour >= slotPMStartHour.value && hour <= slotPMEndHour.value)
    return 'PM';
  else {
    if(hour < slotPMStartHour.value)
      return 'AM';
    else
    return 'PM';
  }    
}

  function getDayOfWeekOnSiteTime(i) {
  const time = computed(() => {
    if(new Date(orderData.value[i]?.strBookTime).getDate())
      return WEEKDAYS[new Date(orderData.value[i]?.strBookTime).getDay()];
    else
      return WEEKDAYS[0];
  });
  if(time.value)
    return time.value;
  else
    return 'emptyKey'
}

function getTxtProvinceName(i) {
    return computed(() => {
    const item = PROVINCES.find(item => item.Id === orderData.value[i]?.provinceId);
    return item ? item.name : '';
  });  
}

  function getTxtOrderStatus(i) {  
    if(orderData.value[i]?.status && orderData.value[i]?.status<6)
      return ORDERSTATUS[orderData.value[i]?.status];
    else 
      return ORDERSTATUS[0];
}

  function getImagePath(catalog) {
  if(catalog === 1) {
    return require('@/assets/service_housecleaning.jpg');
  } else if(catalog === 2) {
    return require('@/assets/service_lawncare.jpg');
  } else if(catalog === 3) {
    return require('@/assets/service_snowremoval.jpg');
  }  
}

  function getTxtCatalog(i) {  
  const catalog = computed(() => {
    const catalogItem = SERVICECATALOG.find(item => item.Id === Number(orderData.value[i]?.serviceCatalog));  
    return catalogItem ? catalogItem.key : 'emptyKey'; 
  });
  if(catalog.value) {
    return catalog.value;
  } else {
    return 'emptyKey';
  }
}

function getStatusColor(status) {

if(status>5) {
  return 'chocolate';
} else {
switch(status) {
  case 0:
    return 'chocolate';
  case 1:
    return '#0aa06e';
  case 2:
    return '#00CED1';
  case 3:
    return '#007BFF';
  case 4:
    return '#8B0000';
  case 5:
    return '#DC3545';      
}
}
}

  function load() {  
    setTimeout(() => {    
      
      if(count.value + 3 <= orderData.value.length)
        count.value += 3;
      else 
        count.value += orderData.value.length - count.value;

    }, 300);
  }


  async function loadUserOrderList() {

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'}); 

    try {
      const response = await apiClient.post('/Staff/getStaffOrders', {
        staffId: curStaff.staffId,
        catalog: listCatalog,
      });

      if(response.data) {
        orderData.value = response.data.orderData;
        slotAMStartHour.value = response.data.slotAMStartHour;
        slotAMEndHour.value = response.data.slotAMEndHour;
        slotPMStartHour.value = response.data.slotPMStartHour;
        slotPMEndHour.value = response.data.slotPMEndHour;
      }

    } catch(error) {      
      if(error.response && error.response.data && error.response.status === 400) {                                                              
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
      } else {                
      if(error.response && error.response.status === 401) {        
          toast.error('Your session has expired, please login again.');           
          store.dispatch('logoutStaff');
          router.push('/staff');
      } else {
          toast.error(error.code);                    
      }                                                            
      }
    } finally {      
      isLoading.close();
    }
  }


  async function handleServiceStartAndEnd(status, orderId) {

    loading.value = true;

    const isLoading = ElLoading.service({lock: true, text: 'Loading', background: 'rgba(0,0,0,0.7)'});  

    try {
      if(status === 1) {
        const response = await apiClient.post('/Staff/startStaffOrder', {
          staffId: curStaff.staffId,
          orderId: orderId,
        });

        if(response.data) {
          toast.success(response.data.msg);
        }

      } else if(status === 2) {
        const response = await apiClient.post('/Staff/endStaffOrder', {
          staffId: curStaff.staffId,
          orderId: orderId,
        });

        if(response.data) {
          toast.success(response.data.msg);
        }
      }

      await loadUserOrderList();
      
    } catch(error) {      
      if(error.response && error.response.data && error.response.status === 400) {                                                              
        errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors); 
      } else {                
      if(error.response && error.response.status === 401) {        
          toast.error('Your session has expired, please login again.');           
          store.dispatch('logoutStaff');
          router.push('/staff');
      } else {
          toast.error(error.code);                    
      }                                                            
      }
    } finally {      
      loading.value = false;
      isLoading.close();      
    }

  }


  onMounted(() =>{       

  if(!store.getters.isStaffLogin) {
      router.push('/staff');
      return;
  }

  loadUserOrderList();

  });

  </script>
  

  <style scoped>
.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;    
}

.divider-line2 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 6px;
  margin-top: 10px;
  width: 94%;
}


h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {  
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  width: 94%;
}

.infinite-list {
  height: 1250px;
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;  
  margin: 0px;  
}
.infinite-list .infinite-list-item + .list-item {
  margin-top: 8px;
}

.avatarList {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;    
  margin: -8px;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

button {
  padding: 10px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  height: 34px;
}

button:hover {
  background-color: #0056b3;
}

  </style>
  