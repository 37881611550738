<template>    
  <div class="container">
    <div class="row" style="padding-bottom: 40px; padding-top: 10px;">
      <div class="d-flex justify-content-center">
        <div>
            <img src="/misc/logo.jpg" width="200px">
        </div>        
      </div>
      <div class="d-flex justify-content-center" style="padding-top: 6px;">
          <div style="font-size: 24px; font-weight: 600;">Your Home Care</div>                
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 26px;">
        <ul>
          <li>Chen Home Tech offers more than just efficient and professional cleaning services—we provide a wide range of household services to create a convenient and comfortable living experience for you.</li>
          <li style="padding-top: 10px;">With a team of experienced professionals, advanced equipment, and eco-friendly products, we deliver high standards for tasks such as regular cleaning, deep cleaning, home organization, and minor repairs.</li>
          <li style="padding-top: 10px;">Our flexible service plans, combined with competitive pricing, ensure that we meet your diverse needs.</li>
          <li style="padding-top: 10px;">Choose us to enjoy top-quality household services with excellent value, making your life easier and more enjoyable!</li>
        </ul>        
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 26px;">
        <div>We are located in Calgary, Alberta, and are dedicated to providing high-quality services to the local community.</div>  
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 10px;">
        <div style="font-weight: 600;">Chen Home Tech Team</div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><LocationInformation /></el-icon></div>
        <div style="font-weight: 600;">18 Tuscany Meadows Common NW<br>Calgary, AB, T3L2L2</div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><Phone /></el-icon></div>
        <div style="font-weight:600; font-size: 16px;">1-825-288-9678 </div>
      </div>
      <div class="d-flex justify-content-start" style="padding-top: 0px;">
        <div style="margin-top: 2px; padding-right: 2px;"><el-icon><MessageBox /></el-icon></div>
        <div style="font-weight:600;color:blue; font-size: 16px;">chenhometechcanada@gmail.com</div>
      </div>
    </div>        
  </div>
</template>
  
  <script setup>
  


  </script>
  
  <style scoped>

  </style>
  