import { computed, createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { createI18n } from 'vue-i18n';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Datepicker from 'vue3-datepicker';
import { useToast } from 'vue-toastification';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const loadLocaleMessages = async (locale) => {
    let messages;
    switch (locale) {
      case 'en':
        messages = await import('./locales/en.json');
        break;
      case 'fr':
        messages = await import('./locales/fr.json');
        break;
      case 'zh':
        messages = await import('./locales/zh.json');
        break;
      case 'ind':
        messages = await import('./locales/ind.json');
        break;
      default:
        messages = await import('./locales/en.json');
    }
    return messages.default || messages;
  };

const savedLanguage = localStorage.getItem('preferredLanguage') || 'en';
  
const i18n = createI18n({
locale: savedLanguage, 
fallbackLocale: 'en', 
legacy: true,
globalInjection: true,
messages: {},
});



async function loadLanguageAsync(lang) {
    if(!i18n.global.availableLocales.includes(lang)) {
        const messages = await import(`./locales/${lang}.json`);
        i18n.global.setLocaleMessage(lang,messages.default);
    }  
    
    i18n.global.locale = lang;   
    
    localStorage.setItem('preferredLanguage',lang);
}


 export {loadLanguageAsync};


loadLocaleMessages(savedLanguage).then((messages) => {

i18n.global.setLocaleMessage(savedLanguage, messages);
i18n.global.locale = savedLanguage;
   
});


const app = createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(i18n)        
    .component('Datepicker', Datepicker);        

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    
  app.use(Toast, {    
    position: 'top-center',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false,     
  });
        
  app.config.globalProperties.$handleTips = function(tag,msg,errors) {

    const toast = useToast();

    switch(tag) {
      case 'succeed':
        toast.success(msg);
        errors[tag] = '';
        break;
      case 'info':
        toast.info(msg);
        errors[tag] = '';
        break;
      case 'warning':
        toast.warning(msg);
        errors[tag] = '';
        break;
      case 'error':
        toast.error(msg);
        errors[tag] = '';
        break;    
      default:
        errors[tag] = msg;
        break;
    }

    return errors;
  };

  app.config.globalProperties.$getConstantItem = function(Id, constant) {
    return computed(() => { return constant.find(a => a.Id === Id); });
  };

  app.config.globalProperties.$getConstantItemKey = function(Id, constant) {
    return computed(() => {
      const item = constant.find(a => a.Id === Id); 
      return item ? item.key : 'emptyKey';
    });    
  };

  app.config.globalProperties.$getTimeZone = (dt) => {

    return computed(() => {
      if(!(dt instanceof Date) || isNaN(dt.getTime())) {
        return 'Invalid time';
      }

      const options = { timeZone: 'America/Edmonton', timeZoneName: 'short' };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(dt);    
      const timeZonePart = parts.find(part => part.type === 'timeZoneName');

      return timeZonePart ? timeZonePart.value : '';        
    });
  };

  app.mount('#app'); 


    