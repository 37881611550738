<template>    
    <div class="container">
        <div class="d-flex justify-content-between" style="align-items: center;">
            <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3>             
        </div>
        <hr class="divider-line0"/>

        <div class="row" style="padding-bottom: 40px; padding-top: 10px;">
            <div class="d-flex justify-content-center">
                <div>
                    <img src="/misc/logo.jpg" width="200px">
                </div>
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 20px;">
                <div style="font-weight: 600;">Chen Home Tech offers multiple payment methods as follows:</div>                
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 20px;">
                <div>
                    1. Pay cash during on-site service.
                </div>
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 10px;">
                <div>
                    2. Pay by card during on-site service (credit/debit).
                </div>
            </div>
            <div class="d-flex justify-content-start" style="padding-top: 10px;">
                <div>
                    3. Pay via E-Transfer to <span style="font-weight: 600; color:blue;">chenhometechcanada@gmail.com</span>, and contact us (1-8252889678) to confirm after completing the payment.
                </div>
            </div>            
        </div>
    </div>  
</template>


<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const curUser = store.getters.user;


onMounted(() =>{
    
if(!store.getters.islogin) {
    router.push('/');
    return;
}

  });

</script>


<style scoped>
h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

h4 {
  margin-bottom: 0px;  
  padding-top: 10px;
  font-weight: 700;
  font-size: 15px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 4px;
}

</style>
    