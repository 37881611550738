<template>  
    <div class="row" style="padding-bottom: 30px;">
        <div class="container">
            <div class="row" style="padding-bottom: 30px;" >
                <div class="d-flex justify-content-between">
                    <div>
                        <h3>Hi, {{ curAdmin?.userName }}.</h3> 
                    </div>                    
                </div>

                <hr class="divider-line0" />
      
                <h2>Admin Settings</h2>
            </div>

            <div class="row mb-4">
                <div class="col-12 col-md-3">
                    <h4>Service</h4>
                </div>
                <div class="links">
                    <a href="#" @click.prevent="goService">Service management ></a>
                </div>                
            </div>

            <div class="row mb-4">
                <div class="col-12 col-md-3">
                    <h4>Staff</h4>
                </div>
                <div class="links">
                    <a href="#"  @click.prevent="goStaff">Staff management ></a>
                </div>
                <div class="links">
                    <a href="#" @click.prevent="goPage('/adminStaffGroupList')">Group management ></a>
                </div>
            </div>

            <div class="row mb-4">
                <div class="col-12 col-md-3">
                    <h4>Order</h4>
                </div>
                <div class="links">
                    <a href="#" @click.prevent="goPage('/adminOrderList')">Order management ></a>
                </div>                
            </div>

            <div class="row mb-4">
                <div class="col-12 col-md-3">
                    <h4>Client User</h4>
                </div>
                <div class="links">
                    <a href="#" @click.prevent="goPage('/adminUserList')">User management ></a>
                </div>                
            </div>

        </div>
    </div>        
</template>

<script setup>
import { useRouter } from 'vue-router'; 
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const curAdmin = store.getters.admin;
if(!store.getters.isAdminLogin) {
    router.push('/admin');    
} 


function goPage(path) {
  router.push(path);
}

function goService() {
    router.push('/adminServiceList');
}

function goStaff() {
  router.push('/adminStaffList');
}

</script>

<style scoped>

.container {
  
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;    
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 30px;
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;          
}

h4 {
  font-size: 20px;
  font-weight: bold;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

</style>