<template>    
  <div class="container">
    <div class="d-flex justify-content-between links" style="align-items: center;">
        <h3>{{ $t('myAccHi') }}, {{ curUser.firstName }}.</h3> 
        <a href="#" @click.prevent="backToStep2">{{ $t('selSvrTime.linkPreStep') }} ></a>
    </div>

    <hr class="divider-line0"/>

    <div class="row">        
      <h2>{{ $t('selSvrTime.mainTitle') }}
      <p style="font-size: 16px; font-weight: 600; color: chocolate;"><span style="font-size: 15px; font-weight: 600; color:#333;">{{ $t('selSvrAdr.curSvrTitle') }}:</span> {{ $t(txtCatalog) }}</p>
      </h2>
      <el-descriptions :title="$t('selSvrDetail.addressTitle')">
        <el-descriptions-item label=""><span style="color:chocolate; font-weight: 600; margin: 0px; padding-top: 0px;"> {{ resultAddress.unit ? resultAddress.unit:'' }} {{ resultAddress.streetNo ? resultAddress.streetNo:'' }} 
          {{ resultAddress.streetName }}<br>&nbsp;&nbsp;&nbsp;&nbsp;{{ resultAddress.city }}, {{ provinceName }} {{ resultAddress.postal }}</span>
        </el-descriptions-item>                                        
      </el-descriptions>      
    </div>  

    <hr class="divider-line0"/>

    <div class="row">
      <el-descriptions :title="$t('selSvrTime.serviceDetailTitle')">

      </el-descriptions>
      <div class="summary">
        <div style="text-align: center; margin: 6px;">
          <span style="color:chocolate; font-weight: 600;">{{ resultService.name }}</span>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding: 16px; padding-bottom: 0px;">
            <div>{{ $t('selSvrDetail.summaryBasePoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ resultService.basePoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div>{{ $t('selSvrDetail.summaryAddPoints') }}: </div>
            <div>
              <span style="color:firebrick">{{ resultService.additionalPoints }}</span>
            </div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="color:green;">{{ $t('selSvrDetail.summaryRankDiscount') }}: </div>
            <div style="color:green;">{{ (rankDiscount/10).toFixed(1) }}%</div>
          </div>
          <div class="d-flex justify-content-center w-100" style="padding: 0px;"><hr class="divider-line1" style="width:94%;"/></div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 0px;">
            <div style="color: firebrick; font-weight: 600;">{{ $t('selSvrDetail.summaryTotalPoints') }}:</div>
            <div style="color:firebrick; font-weight: 600">{{ resultService.totalPoints }}</div>
          </div>
          <div style="font-size: 14px; justify-content: space-between; display: flex; padding-left: 16px; padding-right: 16px; padding-top: 0px; padding-bottom: 10px;">
            <div></div>
            <div style="color:#333;">{{ $t('selSvrDetail.summaryEstimated') }}: ${{(resultService.totalPoints/100).toFixed(1)}}</div>
          </div>
          <div v-show="resultService.specialNotice" style="font-size:14px; text-align: left; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 0px;">
            <div style="color:#007185;"><img src="/misc/notice.png" style="width: 18px"/>&nbsp;{{ $t('selSvrDetail.summaryNotice') }}: {{ resultService.specialNotice }}</div>                          
          </div>
        </div>
      </div>
    </div>

    <hr class="divider-line1"/>

    <form @submit.prevent="handleSubmit">
      <div class="row">
        
        <el-calendar 
          ref="calendar" 
          v-model="currentDate"
          @input="handleDateClick"          
        >          
          <template #header="{ date }">
            <span style="font-weight: 600; font-size: 18px;">{{ date }}</span>
            <div class="d-flex justify-content-space-between">
              <button
                :class="{ 'button-enabled': canGoPrevious, 'button-disabled': !canGoPrevious }"
                @click.prevent="goPrevious"
                :disabled="!canGoPrevious"
              >
                <img src="/misc/arrowsLeft.png" width="20px"/>
              </button>
              &nbsp;
              <button
                :class="{ 'button-enabled': canGoNext, 'button-disabled': !canGoNext }"
                @click.prevent="goNext"
                :disabled="!canGoNext"                
              >
                <img src="/misc/arrowsRight.png" width="20px"/>
              </button>
          </div>
          </template>          
          <template #date-cell="scope">
            <div
              :class="{'disabled-date': isDateDisabled(scope.data.date),
              'enabled-date': !isDateDisabled(scope.data.date)}"
            >
            {{ scope.data.date.getDate() }}
            </div>
          </template>
          
        </el-calendar>
        <el-dialog v-model="dialogFormVisible" width="400" destroy-on-close>
          <div class="d-flex justify-content-center"><span style="color:chocolate; font-size: 16px;; font-weight: 600;">
            {{ $t('selSvrTime.selectedDateDlgTitle') }}: {{ $t(WEEKDAYS[currentDate.getDay()]) }}, {{ MONTHS[currentDate.getMonth()] }}. {{ currentDate.getDate() }}</span></div>
          <hr class="divider-line1"/>
          <div style="padding-top: 0px;"><span style="color:#333; font-weight: 600;">{{ $t('selSvrTime.selectedDateDlgTimeTitle') }}:</span></div>                 
          
            <div class="justify-content-center">                        
              <el-radio-group v-model="selectedPeriod">
                <el-radio                                         
                    value="1"
                    size="large" border                       
                    style="height: auto; width: 340px; padding: 10px; margin: 6px;"         
                    class="radio-text"
                    >
                  {{ $t('selSvrTime.selectedDateDlgTimeAM') }} ({{ slotAMStartHour }}AM-{{ slotAMEndHour }}AM)
                </el-radio>          
              
                <el-radio                                         
                  value="2"
                  size="large" border                       
                  style="height: auto; width: 340px; padding: 10px; margin: 6px;"         
                  class="radio-text"
                  >
                  {{ $t('selSvrTime.selectedDateDlgTimePM') }} ({{ slotPMStartHour }}PM-{{ slotPMEndHour }}PM)
                </el-radio>                   
              </el-radio-group>   
            </div>
            <div class="d-flex justify-content-center" style="margin: 6px; align-items: center;">
              <div v-if="selectedPeriod !=''"><span style="color:#333; font-weight: 600;">{{ $t('selSvrTime.selectedDateDlgTimeHour') }}:&nbsp;&nbsp;</span></div>
              <div>
              <el-time-select
                v-model="selectedMorningHour"
                style="width: 110px"
                :start="txtSlotAMStartHour"
                step="00:30"
                :end="txtSlotAMEndHour"
                placeholder="Hour"
                v-if="selectedPeriod === '1'"              
                placement="bottom-end"  
              />
              <el-time-select
                v-model="selectedAfternoonHour"
                style="width: 110px"
                :start="txtSlotPMStartHour"
                step="00:30"
                :end="txtSlotPMEndHour"
                placeholder="Hour"
                v-if="selectedPeriod === '2'"
                placement="bottom-end"               
              />
              </div>
            </div>
            <hr class="divider-line1"/>
          <div class="d-flex justify-content-center" style="padding: 10px;">                        
            <div class="button-container">
              <button type="submit" :disabled="loading || selectedPeriod === ''" class="submit-button confirmButton">
                <span v-show="!loading" style="font-size: 13px;">{{ $t('selSvrTime.btnSubmit') }}</span>
                <span v-show="loading" class="spinner"></span>
              </button>
            </div>
          </div>          
        </el-dialog>
        <el-dialog v-model="dialogConfirmVisible" width="400" destroy-on-close>
          <div class="d-flex justify-content-center"><span style="color:chocolate; font-size: 16px;; font-weight: 600;">
            {{ $t('selSvrTime.selectedDateDlgTitle') }}: {{ $t(WEEKDAYS[currentDate.getDay()]) }}, {{ MONTHS[currentDate.getMonth()] }}. {{ currentDate.getDate() }}</span></div>
          <hr class="divider-line1"/>
          <div class="d-flex justify-content-center" style="padding: 10px;">                        
            <div class="button-container">
              <button type="submit" :disabled="loading" class="submit-button confirmButton">
                <span v-show="!loading" style="font-size: 13px;">{{ $t('selSvrTime.btnSubmit') }}</span>
                <span v-show="loading" class="spinner"></span>
              </button>
            </div>
          </div>          
        </el-dialog>
      </div>
    </form>

    <hr class="divider-line0"/>

    <div class="row" style="padding-bottom: 16px;">
      <div class="d-flex justify-content-center" style="font-size: 14px;">
        {{ $t('selSvrTime.timezoneTip') }}<span style="font-weight: 600;">&nbsp;{{ proxy.$getTimeZone(new Date()) }}</span>.
      </div>
    </div>

  </div>
</template>
    

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'; 
import { useToast } from 'vue-toastification';
import { computed, getCurrentInstance } from 'vue';
import { ref,onMounted } from 'vue';
import apiClient from '@/apiClient.js';
import { SERVICECATALOG,WEEKDAYS,MONTHS,PROVINCES } from '@/constants';

const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const toast = useToast();
const errors = ref({});
const resultAddress = ref({});
const loading = ref(false);   
const rankDiscount = ref('');
const resultService = ref({});
const selectedPeriod = ref('');
const selectedMorningHour = ref('');
const selectedAfternoonHour = ref('');
const dayLatestBookTime = ref(14);
const slotAMStartHour = ref(9);
const slotAMEndHour = ref(11);
const slotPMStartHour = ref(12);
const slotPMEndHour = ref(14);

const dialogFormVisible = ref(false);
const dialogConfirmVisible = ref(false);

const curUser = store.getters.user;
  if(!store.getters.islogin) {
      router.push('/');
  }

const addressId = Number(router.currentRoute.value.params.addressId);
const catalog = Number(router.currentRoute.value.params.catalog);
const serviceId = Number(router.currentRoute.value.params.serviceId);

const catalogItem = SERVICECATALOG.find(item => item.Id === catalog);        
const txtCatalog = catalogItem ? catalogItem.key : 'emptyKey';

const calendar = ref(null);
const currentDate = ref(new Date());

const txtSlotAMStartHour = computed(() => {
  return `${slotAMStartHour.value}:00`;
});

const txtSlotAMEndHour = computed(() => {
  return `${slotAMEndHour.value}:00`;
});

const txtSlotPMStartHour = computed(() => {
  return `${slotPMStartHour.value}:00`;
});

const txtSlotPMEndHour = computed(() => {
  return `${slotPMEndHour.value}:00`;
});

const provinceName = computed(() => {
    const item = PROVINCES.find(item => item.Id === resultAddress.value.provinceId);
    return item ? item.name : '';
  });

const canGoPrevious = computed(() => {
  const now = new Date();
  return (now.getMonth() < currentDate.value.getMonth() && now.getFullYear()===currentDate.value.getFullYear()) || now.getFullYear()< currentDate.value.getFullYear();  
});

const canGoNext = computed(() => { 
  const halfYearLater = ref(new Date());
  halfYearLater.value.setMonth(halfYearLater.value.getMonth()+5);
  return currentDate.value < halfYearLater.value;
});


function isDateDisabled(date) {
  const today = new Date();
  return date.setHours(0,0,0,0) < today.setHours(0,0,0,0);
}


function goNext() {
  calendar.value.selectDate('next-month');

  dialogFormVisible.value = false;
  dialogConfirmVisible.value = false;
}

function goPrevious() {  
    
  calendar.value.selectDate('prev-month');  

  const now = new Date();
  if(currentDate.value.getMonth() == now.getMonth() && currentDate.value.getFullYear() === now.getFullYear()) {
    currentDate.value = now;    
  }

  dialogFormVisible.value = false;
  dialogConfirmVisible.value = false;
}


function backToStep2() {
    router.push({ name: 'selectServiceDetail', params: { catalog: catalog, addressId: addressId }});
}

const handleDateClick = () => {

  if(catalog === 1) {
    dialogFormVisible.value = true;
  } else {
    dialogConfirmVisible.value = true;
  }
         
}


async function loadServiceDetail() {
  
try {
  const response = await apiClient.post('/Service/getUserAvailableServiceTime', {
    userId: curUser.userId,
    serviceCatalog: catalog,
    addressId: addressId,
    serviceId: serviceId
  });

  resultAddress.value = response.data.contactInfo;  
  resultService.value = response.data.service;
  rankDiscount.value = response.data.rankDiscount;
  dayLatestBookTime.value = response.data.dayLatestBookTime;
  slotAMStartHour.value = response.data.slotAMStartHour;
  slotAMEndHour.value = response.data.slotAMEndHour;
  slotPMStartHour.value = response.data.slotPMStartHour;
  slotPMEndHour.value = response.data.slotPMEndHour;

} catch(error) {
    if(error.response && error.response.data && error.response.status == 400) {                    
            errors.value = proxy.$handleTips(error.response.data.tag, error.response.data.msg,errors);                    
        } else {                
          if(error.response && error.response.status == 401) {
            toast.error('Your session has expired, please login again.');           
            store.dispatch('logout');
            router.push('/');
          } else {
            toast.error(error.code);                    
          }                                                            
    }
}
}

function handleSubmit() {

  const selTime = ref('');

  // Check hour and minutes
  if(dialogFormVisible.value === true) {
    if(selectedPeriod.value === '') {
      toast.error('Must select AM or PM.');
      return;
    } else {
      if(selectedPeriod.value === '1') {
        if(selectedMorningHour.value === '') {
          toast.error('Must select on-site service time.');
          return;
        }
        selTime.value = selectedMorningHour.value;
      } else if(selectedPeriod.value === '2') {
        if(selectedAfternoonHour.value === '') {
          toast.error('Must select on-site service time.');
          return;
        }
        selTime.value = selectedAfternoonHour.value;
      }
    }
        
    const [hour,minute] = selTime.value.split(":");
    console.log(minute);

    // Check if selected time is today    
    const now = new Date();
    if(currentDate.value.getFullYear() == now.getFullYear() && 
      currentDate.value.getMonth() == now.getMonth() && 
      currentDate.value.getDate() == now.getDate()) {
        if(selectedPeriod.value === '1') {
          if(currentDate.value.getHours() >= hour) {          
            toast.error(`Reservations for today cannot be made after ${hour} AM. Please select another time.`);
            return;
          }
        } else if(selectedPeriod.value === '2') {
          if(currentDate.value.getHours() >= hour) {
            toast.error(`Reservations for today cannot be made after ${hour} PM. Please select another time.`);
            return;
          }
        }       
      }

  } else if(dialogConfirmVisible.value === true) {
    selectedPeriod.value = '0';
    selTime.value = '0'

    // Check if selected time is today    
    const now = new Date();
    if(currentDate.value.getFullYear() == now.getFullYear() && 
      currentDate.value.getMonth() == now.getMonth() && 
      currentDate.value.getDate() == now.getDate()) {        
        if(currentDate.value.getHours()>= dayLatestBookTime.value) {          
          toast.error(`Reservations for today cannot be made after ${dayLatestBookTime.value}:00 PM. Please select another time.`);
          return;
        }        
      }
  }  


  loading.value = true;

  setTimeout(() => {
    loading.value = false;
    dialogFormVisible.value = false;
    dialogConfirmVisible.value = false;

    router.push({name: 'serviceOrderSummary', params: {
      catalog: catalog, 
      addressId: addressId, 
      serviceId: serviceId, 
      yyyy: currentDate.value.getFullYear(),
      mm: currentDate.value.getMonth()+1,
      dd: currentDate.value.getDate(),
      slot: selectedPeriod.value,
      hm: selTime.value
    }});

  }, 200);
}


onMounted(() =>{    
    loadServiceDetail();

  });

</script>
    

<style scoped>

.el-calendar {
  height: auto; 
  overflow: hidden; 
}

.summary-container {
  display: flex;
  flex-direction: column; 
  align-items: center;    
  justify-content: center; 
  width: 100%;            
  padding-top: 20px;
  padding-bottom: 10px;
}

.summary {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;    
  margin: 10px;
  overflow: hidden;  
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 00px;
  width: 94%;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;  
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #ffffff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

h2 {
  margin-bottom: 24px;  
  font-weight: 500;
  font-size: 24px;
  color: #333;    
  text-align: center;    
  padding-top: 10px;
  padding-bottom: 10px;
}

h3 {
  margin-bottom: 14px;  
  padding-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #333;         
}

.divider-line {
  border: none;
  border-top: 1px solid #ccc;
  margin: 30px 0;
}

.divider-line0 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
}

.divider-line1 {
  border: none;
  border-top: 1px solid #ccc;
  margin: 0px 0;
  margin-bottom: 10px;
  margin-top: 16px;
}

.links {
  text-align: left;
  width: auto;
}

.links a {
  display: block;
  color: #007aff;
  text-decoration: none;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}


.button-enabled {
  color: black;
  background-color: white;
  border: 1px solid black;
}

.button-disabled {
  color: gray;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

button {
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;  
}

button:disabled {
  cursor: not-allowed;
  
}

button:disabled img {
  filter: contrast(10%); 
}


::v-deep .el-calendar-table th {
  text-align: center; 
  padding: 4px 0;    
  font-weight: bold;  
  font-size: 16px;    
}

::v-deep .el-calendar-table td {
  border: none !important;      
}
::v-deep .el-calendar-table td:hover {
  background-color: #ffffff;  
}

::v-deep .el-calendar-day {
  font-size: 14px;
  font-weight: 500;   
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;       
}

::v-deep .current .el-calendar-day {  
  color: #333 !important;      
}

::v-deep .current .el-calendar-day:hover {  
  background-color:#333;
  color: #ffffff !important;
}

::v-deep .is-today .el-calendar-day {
  color: #333 !important;
  font-weight: bold !important;  
  border: 1px solid black;
}

::v-deep .is-selected .el-calendar-day {  
  color: #ffffff !important;  
  font-weight: bold !important;
  border: 1px solid black;
  background-color: #333;
}

::v-deep .next .el-calendar-day {
  font-size: 1pt !important;
  color:#ffffff !important;  
}

::v-deep
  .next,
 .next .el-calendar-day {    
  cursor: not-allowed !important; 
  pointer-events: none !important; 
  opacity: 0.6 !important;   
}

::v-deep .next .el-calendar-day:hover {
  background-color: #ffffff;
}

::v-deep
  .prev,
 .prev .el-calendar-day {    
  cursor: not-allowed !important; 
  pointer-events: none !important; 
  opacity: 0.6 !important;   
}

::v-deep .prev .el-calendar-day:hover {
  background-color: #ffffff;
}

::v-deep .prev .el-calendar-day {
  font-size: 1pt !important;
  color:#ffffff !important;  
}

::v-deep   
  .disabled-date {
  color: gray;  
  background-color: #ffffff;
  opacity: 0.6;
  text-decoration: line-through;
}

::v-deep   
  .current,
  .disabled-date {
    cursor: not-allowed !important; 
    pointer-events: none !important;
}

::v-deep 
  .current,
  .enabled-date {
  cursor: pointer;
  pointer-events:all; 
  opacity:inherit;
}

.radio-text {
  white-space: normal; 
  word-wrap: break-word;
  display: inline-block; 
  align-items: flex-start;
}

.confirmButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 70%;  
}

.confirmButton:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: center; 
  width: 100%;
  margin-bottom: 10px; 
}


.el-time-select .el-input {
  zoom: 0.9; 
}

</style>
    